export const PLAYERS_LIST_REQUEST = 'PLAYERS_LIST_REQUEST';
export const PLAYERS_LIST_RECEIVED = 'PLAYERS_LIST_RECEIVED';
export const PLAYERS_LIST_ERROR = 'PLAYERS_LIST_ERROR';
export const BLOG_POST_LIST_ADD = 'BLOG_POST_LIST_ADD';
export const PLAYERS_LIST_SET_PAGE = 'PLAYERS_LIST_SET_PAGE';

export const TOURNAMENTS_LIST_REQUEST = 'TOURNAMENTS_LIST_REQUEST';
export const TOURNAMENTS_LIST_ERROR = 'TOURNAMENTS_LIST_ERROR';
export const TOURNAMENTS_LIST_RECEIVED = 'TOURNAMENTS_LIST_RECEIVED';
export const TOURNAMENTS_LIST_SET_PAGE = 'TOURNAMENTS_LIST_SET_PAGE';
export const TOURNAMENTS_CALENDAR_SET_CATEGORY = 'TOURNAMENTS_CALENDAR_SET_CATEGORY';

export const TOURNAMENTS_RESULT_REQUEST = 'TOURNAMENTS_RESULT_REQUEST';
export const CLASSIFICATION_LIST_CLEAR = 'CLASSIFICATION_LIST_CLEAR';
export const TOURNAMENTS_RESULT_ERROR = 'TOURNAMENTS_RESULT_ERROR';
export const TOURNAMENTS_RESULT_RECEIVED = 'TOURNAMENTS_RESULT_RECEIVED';
export const TOURNAMENTS_RESULT_SET_PAGE = 'TOURNAMENTS_RESULT_SET_PAGE';
export const TOURNAMENTS_RESULT_CHANGE_PAGE = 'TOURNAMENTS_RESULT_CHANGE_PAGE';
export const TOURNAMENTS_RESULT_SET_CATEGORY = 'TOURNAMENTS_RESULT_SET_CATEGORY';
export const TOURNAMENTS_LIST_SET_SEASON = 'TOURNAMENTS_LIST_SET_SEASON';

export const CLASSIFICATION_LIST_REQUEST = 'CLASSIFICATION_LIST_REQUEST';
export const CLASSIFICATION_LIST_SET_SEASON = 'CLASSIFICATION_LIST_SET_SEASON';
export const CLASSIFICATION_LIST_ERROR = 'CLASSIFICATION_LIST_ERROR';
export const CLASSIFICATION_LIST_RECEIVED = 'CLASSIFICATION_LIST_RECEIVED';
export const CLASSIFICATION_LIST_SET_TYPE_OF_GAME = 'CLASSIFICATION_LIST_SET_TYPE_OF_GAME';
export const CLASSIFICATION_LIST_SET_CATEGORY = 'CLASSIFICATION_LIST_SET_CATEGORY';

export const TOURNAMENT_REQUEST = 'TOURNAMENT_REQUEST';
export const TOURNAMENT_RECEIVED = 'TOURNAMENT_RECEIVED';
export const TOURNAMENT_ERROR = 'TOURNAMENT_ERROR';
export const TOURNAMENT_UNLOAD = 'TOURNAMENT_UNLOAD';
export const TOURNAMENT_REGISTER_SUCCESS = 'TOURNAMENT_REGISTER_SUCCESS';

export const PLAYER_CATEGORIES_REQUEST = 'PLAYER_CATEGORIES_REQUEST';
export const PLAYER_CATEGORIES_RECEIVED = 'PLAYER_CATEGORIES_RECEIVED';
export const PLAYER_CATEGORIES_ERROR = 'PLAYER_CATEGORIES_ERROR';
export const PLAYER_CATEGORIES_SET_PAGE = 'PLAYER_CATEGORIES_SET_PAGE';

export const COMMON_REQUEST = 'COMMON_REQUEST';
export const COMMON_RECEIVED = 'COMMON_RECEIVED';
export const COMMON_ERROR = 'COMMON_ERROR';
export const COMMON_UNLOAD = 'COMMON_UNLOAD';

export const CLUB_REQUEST = 'CLUB_REQUEST';
export const CLUB_RECEIVED = 'CLUB_RECEIVED';
export const CLUB_ERROR = 'CLUB_ERROR';
export const CLUB_UNLOAD = 'CLUB_UNLOAD';
export const CLUB_REGISTER_SUCCESS = 'CLUB_UNLOAD';
export const BLOG_POST_FORM_UNLOAD = 'BLOG_POST_FORM_UNLOAD';

export const CLUB_PLAYERS_LIST_REQUEST = 'CLUB_PLAYERS_LIST_REQUEST';
export const CLUB_PLAYERS_LIST_RECEIVED = 'CLUB_PLAYERS_LIST_RECEIVED';
export const CLUB_PLAYERS_LIST_ERROR = 'CLUB_PLAYERS_LIST_ERROR';
export const CLUB_PLAYERS_LIST_SET_PAGE = 'CLUB_PLAYERS_LIST_SET_PAGE';

export const CLUB_LIST_ERROR = 'CLUB_LIST_ERROR';
export const CLUB_LIST_RECEIVED = 'CLUB_LIST_RECEIVED';
export const CLUB_LIST_REQUEST = 'CLUB_LIST_REQUEST';
export const CLUB_LIST_SET_PAGE = 'CLUB_LIST_SET_PAGE';

export const COMMENT_ADDED = 'COMMENT_ADDED';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_SET_ID = 'USER_SET_ID';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_COMPLETE = 'USER_REGISTER_COMPLETE';
export const USER_CONFIRMATION_SUCCESS = 'USER_CONFIRMATION_SUCCESS';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_RECEIVED = 'USER_PROFILE_RECEIVED';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';

export const IMAGE_UPLOADED = 'IMAGE_UPLOADED';
export const IMAGE_UPLOAD_REQUEST = 'IMAGE_UPLOAD_REQUEST';
export const IMAGE_UPLOAD_ERROR = 'IMAGE_UPLOAD_ERROR';
export const IMAGE_DELETED = 'IMAGE_DELETED';
export const IMAGE_DELETE_REQUEST = 'IMAGE_DELETE_REQUEST';

export const LAST_SEASON_RECEIVED = 'LAST_SEASON_RECEIVED';
export const LAST_SEASON_ERROR = 'LAST_SEASON_ERROR';
export const LAST_SEASON_REQUEST = 'LAST_SEASON_REQUEST';

export const CURRENT_SEASON_RECEIVED = 'CURRENT_SEASON_RECEIVED';
export const CURRENT_SEASON_ERROR = 'CURRENT_SEASON_ERROR';
export const CURRENT_SEASON_REQUEST = 'CURRENT_SEASON_REQUEST';

export const SEASON_LIST_RECEIVED = 'SEASON_LIST_RECEIVED';
export const SEASON_LIST_ERROR = 'SEASON_LIST_ERROR';
export const SEASON_LIST_REQUEST = 'SEASON_LIST_REQUEST';

export const PLAYERS_LIST_UPLOADED = 'PLAYERS_LIST_UPLOADED';
export const PLAYERS_LIST_UPLOAD_REQUEST = 'PLAYERS_LIST_UPLOAD_REQUEST';
export const PLAYERS_LIST_UPLOAD_ERROR = 'PLAYERS_LIST_UPLOAD_ERROR';
export const PLAYERS_LIST_DELETE_REQUEST = 'PLAYERS_LIST_DELETE_REQUEST';
export const PLAYERS_LIST_DELETED = 'PLAYERS_LIST_DELETED';
export const UPDATE_PLAYERS_LIST_FORM_UNLOAD = 'UPDATE_PLAYERS_LIST_FORM_UNLOAD';

